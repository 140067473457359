import React, { useEffect, useState } from 'react';
import './SubscriptionPage.css';
import { Typography } from '@mui/material';
import { CommonSevice } from '../../services/common';
import { showToast } from '../../utils/common.util';
import { ToastThemes } from '../../constants/common';

const SubscriptionPage = () => {
  const [activePlan, setActivePlan] = useState('standard');
  const [premiumUserCount, setPremiumUserCount] = useState(10);
  const [premiumAdminCount, setPremiumAdminCount] = useState(1);
  const [plans, setPlans] = useState([]);
  const [defaultCount, setDefaultCount] = useState({});
  const [data, setData] = useState({});

  const hostname = window.location.hostname;
  const tenant = hostname.split('.')[0]; 
    // === 'localhost'
    //   ? process.env.REACT_APP_LOCALHOST_CLIENT_ID
    //   : hostname.split('.')[0];

  useEffect(async () => {
    const apiResponse = await new CommonSevice().fetchPlanDetails();
    setData(apiResponse);
    const activeType = apiResponse.activePlan.type.toLowerCase();
    setActivePlan(activeType);
  }, []);

  useEffect(async () => {
    let parsedPlans = [];
    if(activePlan === 'standard'){
    parsedPlans = [
      {
        name: 'Standard',
        key: 'standard',
        // price: 'Free',
        features: data.activePlan.features.map((feature) => ({
          label: `${feature.name}: ${feature.count} ${
            feature.frequency === 'M'
              ? 'Monthly'
              : feature.frequency === 'Y'
              ? 'Yearly'
              : ''
          }`,
        })),
      },
      {
        name: 'Premium',
        key: 'premium',
        // price: `$${
        //   (premiumUserCount * 2) + (premiumAdminCount  * 50)
        // }/month`,
        features: data.secondaryPlan.features.map((feature) => {
          if (feature.name.includes('Users')) {
            setPremiumUserCount(feature.count);
            setDefaultCount((prev) => ({ ...prev, user: feature.count }));
          }
          if (feature.name.includes('Admins')) {
            setPremiumAdminCount(feature.count);
            setDefaultCount((prev) => ({ ...prev, admin: feature.count }));
          }

          return {
            label: `${feature.name}: ${feature.count} ${
              feature.frequency === 'M'
                ? 'Monthly'
                : feature.frequency === 'Y'
                ? 'Yearly'
                : ''
            }`,
            controls:
              (feature.name.includes('Users') ||
                feature.name.includes('Admins')) &&
              activePlan === 'premium'
                ? true
                : false,
          };
        }),
      },
      {
        name: 'Enterprise',
        key: 'enterprise',
        // price: 'Custom Pricing',
        features: [
          { label: 'Unlimited Users' },
          { label: 'Dedicated Account Manager' },
          { label: 'Custom Integrations & Features' },
          { label: '24/7 Support' },
        ],
      },
    ];
  } else if(activePlan === 'premium') {

    parsedPlans = [
      {
        name: 'Standard',
        key: 'standard',
        // price: 'Free',
        features: data.secondaryPlan.features.map((feature) => ({
          label: `${feature.name}: ${feature.count} ${
            feature.frequency === 'M'
              ? 'Monthly'
              : feature.frequency === 'Y'
              ? 'Yearly'
              : ''
          }`,
        })),
      },
      {
        name: 'Premium',
        key: 'premium',
        // price: `$${
        //   (premiumUserCount * 2) + (premiumAdminCount  * 50)
        // }/month`,
        features: data.activePlan.features.map((feature) => {
          if (feature.name.includes('Users')) {
            setPremiumUserCount(feature.count);
            setDefaultCount((prev) => ({ ...prev, user: feature.count }));
          }
          if (feature.name.includes('Admins')) {
            setPremiumAdminCount(feature.count);
            setDefaultCount((prev) => ({ ...prev, admin: feature.count }));
          }

          return {
            label: `${feature.name}: ${feature.count} ${
              feature.frequency === 'M'
                ? 'Monthly'
                : feature.frequency === 'Y'
                ? 'Yearly'
                : ''
            }`,
            controls:
              (feature.name.includes('Users') ||
                feature.name.includes('Admins')) &&
              activePlan === 'premium'
                ? true
                : false,
          };
        }),
      },
      {
        name: 'Enterprise',
        key: 'enterprise',
        // price: 'Custom Pricing',
        features: [
          { label: 'Unlimited Users' },
          { label: 'Dedicated Account Manager' },
          { label: 'Custom Integrations & Features' },
          { label: '24/7 Support' },
        ],
      },
    ];
  }

    setPlans(parsedPlans);
  }, [activePlan, data]);

  const handleEnterpriseRequest = async () => {
    try {
      // Simulate a backend request
      alert(
        'Enterprise request sent to the backend. We will contact you shortly.'
      );
    } catch (error) {
      alert('Failed to send request. Please try again later.');
    }
  };

  // const plans = [
  //   {
  //     name: 'Standard',
  //     key: 'standard',
  //     price: 'Free',
  //     features: [
  //       { label: 'Up to 10 users' },
  //       { label: '1 Tenant Admin' },
  //       { label: '3 Job/month' },
  //       { label: '30 Candidate/month' },
  //       { label: '12 Goals/month' },
  //       { label: 'Community Support' },
  //     ],
  //   },
  //   {
  //     name: 'Premium',
  //     key: 'premium',
  //     basePrice: 20,
  //     price: `$${20 + (premiumUserCount - 10) * 2 + (premiumAdminCount - 1) * 5}/month`,
  //     features: [
  //       { label: 'Up to 10 users', controls: activePlan === 'premium' },
  //       { label: '1 Tenant Admin', controls: activePlan === 'premium' },
  //       { label: '30 Job/month' },
  //       { label: '300 Candidate/month' },
  //       { label: '120 Goals/month' },
  //       { label: 'Priority Support' },
  //       { label: 'Custom Integrations' },
  //     ],
  //   },
  //   {
  //     name: 'Enterprise',
  //     key: 'enterprise',
  //     price: 'Custom Pricing',
  //     features: [
  //       { label: 'Unlimited Users' },
  //       { label: 'Dedicated Account Manager' },
  //       { label: 'Custom Integrations & Features' },
  //       { label: '24/7 Support' },
  //     ],
  //   },
  // ];

  const handlePlanChange = async (planKey) => {
    if (planKey === 'premium') {
      const confirmed = window.confirm('Do you want to activate Premium?');
      if (confirmed) {
        await new CommonSevice()
          .changePlan(
            {
              action: 'ChangePlanFromApp',
              subscriptionId: data.activePlan.subscriptionId,
              planId: data.secondaryPlan.microsoftPlanId,
            },
            tenant
          )
          .then(() => {
            showToast('Thank You! Request Sent', ToastThemes.success);
          })
          .catch((err) => {
            showToast(
              err?.response?.data?.message || 'Something Went Wrong! Try Again',
              ToastThemes.error
            );
            throw err?.response?.data?.message;
          });
        // setActivePlan(planKey);
      }
    } else {
      const confirmed = window.confirm(
        'Do you want to switch back to Standard?'
      );
      if (confirmed) {
        await new CommonSevice().changePlan(
          {
            action: 'ChangePlanFromApp',
            subscriptionId: data.activePlan.subscriptionId,
            planId: data.secondaryPlan.microsoftPlanId,
          },
          tenant
        ).then(() => {
          showToast('Thank You! Request Sent', ToastThemes.success);
        })
        .catch((err) => {
          showToast(
            err?.response?.data?.message || 'Something Went Wrong! Try Again',
            ToastThemes.error
          );
          throw err?.response?.data?.message;
        });

        // setActivePlan(planKey);
      }
    }
  };

  const incrementUserCount = () => {
    setPremiumUserCount((prevCount) => prevCount + 1);
  };

  const decrementUserCount = () => {
    setPremiumUserCount((prevCount) =>
      Math.max(defaultCount.user, prevCount - 1)
    );
  };

  const incrementAdminCount = () => {
    setPremiumAdminCount((prevCount) => prevCount + 1);
  };

  const decrementAdminCount = () => {
    setPremiumAdminCount((prevCount) =>
      Math.max(defaultCount.admin, prevCount - 1)
    );
  };

  return (
    <div className="subscription-page">
      <h1>Choose Your Subscription Plan</h1>
      <p className="info">
        Select a plan that best fits your needs. You can always switch between
        plans.
      </p>
      <br />
      <div className="plans-container">
        {plans.map((plan) => (
          <div
            key={plan.key}
            className={`plan-card ${activePlan === plan.key ? 'active' : ''}`}
          >
            <h2
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {plan.name}
            </h2>
            <p className="price">{plan.price}</p>
            <ul>
              {plan.features.map((feature, index) => (
                <li key={index} className="feature-item">
                  <Typography marginBottom={1}>{feature.label}</Typography>
                  {feature.controls && (
                    <div className="feature-controls">
                      {feature.label.includes('Users') && (
                        <>
                          <button onClick={decrementUserCount}>-</button>
                          <span>
                            {premiumUserCount < 10 ? 0 : ''}
                            {premiumUserCount}
                          </span>
                          <button onClick={incrementUserCount}>+</button>
                        </>
                      )}
                      {feature.label.includes('Admins') && (
                        <>
                          <button onClick={decrementAdminCount}>-</button>
                          <span>
                            {premiumAdminCount < 10 ? 0 : ''}
                            {premiumAdminCount}
                          </span>
                          <button onClick={incrementAdminCount}>+</button>
                        </>
                      )}
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <div className="plan-actions">
              {plan.key === 'premium' && activePlan === 'premium' && (
                <button onClick={() => alert('Counts updated successfully!')}>
                  Update Counts
                </button>
              )}

              {plan.key === 'premium' && activePlan !== 'premium' && (
                <button onClick={() => handlePlanChange(plan.key)}>
                  Upgrade to Premium
                </button>
              )}

              {plan.key === 'standard' && activePlan !== 'standard' && (
                <button onClick={() => handlePlanChange(plan.key)}>
                  Switch to Standard
                </button>
              )}

              {plan.key === 'enterprise' && (
                <button onClick={handleEnterpriseRequest} disabled>
                  Request Enterprise Plan
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPage;
