import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Checkbox,
  FormHelperText,
  ListItemText,
  Select,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { getCommaSeparatedStringFromList } from '../../utils/common.util';
import FieldView from '../../components/molecules/FieldView/FieldView';
import { LightTooltip } from '../Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';
import ChipTag from '../ChipTag/ChipTag';
import Badge from '@mui/material/Badge';

export default function ApiSelectTag(props) {
  const {
    id,
    defaultValue,
    placeholder,
    labelText,
    size,
    hiddenLabel,
    fullWidth,
    widthAuto,
    required,
    dropDownList = [],
    name,
    value,
    onchange,
    onOpen,
    loadingStatus,
    isMulti,
    errorMessage,
    onChangeMultiSelect,
    disabled,
    hasCustomIcon,
    isReadOnly,
    infoIcon,
    isAllSelected,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(value);

  const handleDropdownExpand = () => {
    if (isEmpty(dropDownList)) {
      onOpen().then((success) => {
        if (success) {
          setOpen(true);
        }
      });
    } else {
      setOpen(true);
    }
  };

  const renderDropdownList = () => {
    if (isMulti) {
      const menuItems = [
        <MenuItem
          key={'all'}
          value={{ id: 'all', name: 'Select All' }}
          selected={isAllSelected ?? dropDownList?.length === value?.length}
        >
          <Checkbox
            checked={isAllSelected ?? dropDownList?.length === value?.length}
          />
          <ListItemText primary="Select All" />
        </MenuItem>,
      ];
      dropDownList?.forEach((item) => {
        const { name, id } = item;
        const selected = !!value?.length && !!value?.find((v) => v?.id === id);
        menuItems.push(
          <MenuItem key={id} value={{ id, name }} selected={selected}>
            <Checkbox checked={selected} />
            <ListItemText primary={name} />
          </MenuItem>
        );
      });

      return menuItems;
    } else {
      return (
        dropDownList?.map((item) => (
          <MenuItem key={item.id} value={item}>
            {item.name}
          </MenuItem>
        )) || []
      );
    }
  };

  const getRenderValue = () => {
    if (value) {
      if (isMulti) {
        if (value?.length === 0) {
          return <Box sx={{ color: 'neutral.dark40' }}>{defaultValue}</Box>;
        }
        return getCommaSeparatedStringFromList(value);
      }
      return value.id === 0
        ? value.name
        : value.name ||
            dropDownList?.find((item) => item?.id === value)?.name ||
            value;
    } else {
      return <Box sx={{ color: 'neutral.dark40' }}>{defaultValue}</Box>;
    }
  };
  if (isReadOnly) {
    return (
      <FieldView
        labelText={labelText}
        labelValue={isMulti ? <ChipTag values={value} /> : value?.name}
      />
    );
  }
  return (
    <Box>
      <FormControl
        error={errorMessage}
        sx={{ width: fullWidth ? '100%' : widthAuto ? 'auto' : 150 }}
        required={required}
      >
        {labelText && (
          <Typography
            variant="body2"
            color={disabled ? 'neutral.light40' : 'neutral.dark80'}
            mb={1}
          >
            {labelText}
            {required && (
              <Box
                color="accentSecondary.main"
                sx={{ display: 'inline-block' }}
              >
                *
              </Box>
            )}
            {infoIcon && (
              <LightTooltip title={infoIcon} placement="top" arrow>
                <Box color="primary.main" sx={{ display: 'inline-block' }}>
                  <InfoOutlined
                    sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                  />
                </Box>
              </LightTooltip>
            )}
          </Typography>
        )}
        {isMulti && (
          <Badge badgeContent={isMulti ? value?.length : ''} color="primary" />
        )}
        <Select
          value={isMulti && !value ? [] : value}
          onChange={isMulti ? onChangeMultiSelect : onchange}
          name={name}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          size={size}
          fullWidth={fullWidth}
          multiple={isMulti}
          onOpen={onOpen ? handleDropdownExpand : () => setOpen(true)}
          open={open}
          onClose={() => setOpen(false)}
          renderValue={getRenderValue}
          disabled={disabled}
          MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
        >
          {renderDropdownList()}
        </Select>
        {errorMessage && (
          <FormHelperText style={{ color: '#d32f2f' }}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
