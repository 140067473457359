import API from './api';

export class CommonSevice extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL || '';
  constructor() {
    super(CommonSevice.baseURL);
  }
  getDropDown = async (fieldName) =>
    this.responsify(
      await this.instance.post(`common/utils/dropdown/${fieldName}`)
    );

  postDropDown = async (fieldName, benifitId) =>
    this.responsify(
      await this.instance.post(`common/utils/dropdown/${fieldName}`, {
        searchIds: [benifitId],
      })
    );

  sendOTP = async (body) =>
    this.responsify(await this.instance.post('common/utils/send-otp', body));

  sendToken = async (token, email) =>
    this.responsify(await this.instance.post(`common/utils/accept-webhook?token=${token}&email=${email}`));

  verifyOTP = async (body) =>
    this.responsify(await this.instance.post('common/utils/verify-otp', body));

  getProductList = async () =>
    this.responsify(await this.instance.get('common/utils/products'));

  getFeedbackOption = async () =>
    this.responsify(await this.instance.get('feedback/options'));

  postFeedBack = async (body) =>
    this.responsify(await this.instance.post('feedback/post-feedback', body));

  fetchPlanDetails = async () =>
    this.responsify(await this.instance.get('common/utils/fetch-plan-details'));

  changePlan = async (body, client_id) =>
    this.responsify(await this.instance.post('common/utils/accept-webhook', body, {params:{client_id}}));
}
